import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import YouTube1 from '../components/you-tube1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>EV Charging Station Management, Innovative Solutions.</title>
        <meta
          name="description"
          content="Choose our SaaS platform for managing EV charging stations. Explore data, real-time monitoring, and efficient user management."
        />
        <meta
          property="og:title"
          content="EV Charging Station Management, Innovative Solutions."
        />
        <meta
          property="og:description"
          content="Choose our SaaS platform for managing EV charging stations. Explore data, real-time monitoring, and efficient user management."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/cc7357e8-7d79-4b73-819e-adbd56b94d67/97823c71-219c-4921-8a12-985973d7fce6?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section id="hero" className="home-hero">
        <header className="home-header1">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="pastedImage"
              src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
              className="home-logo1"
            />
            <div className="home-links1">
              <span className="Link">Solutions</span>
              <span className="Link">How it works</span>
              <span className="Link">Prices</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon10">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <header className="home-container11">
              <header data-thq="thq-navbar" className="home-navbar-interactive">
                <img
                  alt="logo"
                  sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
                  src="/ev%20%20tools%20logo%20(1)-1100h.webp"
                  srcSet="/ev%20%20tools%20logo%20(1)-1100h.webp 1200w, /ev%20%20tools%20logo%20(1)-tablet.webp 800w, /ev%20%20tools%20logo%20(1)-mobile.webp 480w"
                  className="home-image1"
                />
                <div data-thq="thq-navbar-nav" className="home-desktop-menu">
                  <nav className="home-links2">
                    <a
                      href="#hero"
                      className="home-link11 thq-link thq-body-small"
                    >
                      Home
                    </a>
                    <a
                      href="#title"
                      className="home-link21 thq-link thq-body-small"
                    >
                      Features
                    </a>
                    <a
                      href="#banner - advanced analytics"
                      className="home-link31 thq-link thq-body-small"
                    >
                      {' '}
                      Managment
                    </a>
                    <a
                      href="##action"
                      className="home-link32 thq-link thq-body-small"
                    >
                      {' '}
                      Mobile
                    </a>
                    <a
                      href="##container"
                      className="home-link33 thq-link thq-body-small"
                    >
                      云快充
                    </a>
                  </nav>
                  <a href="#footer" className="home-link10">
                    <div className="home-get-started1 template-button">
                      <span className="home-text13">Contact Us</span>
                    </div>
                  </a>
                </div>
                <div data-thq="thq-burger-menu" className="home-burger-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                </div>
                <div data-thq="thq-mobile-menu" className="home-mobile-menu">
                  <div className="home-nav">
                    <div className="home-top1">
                      <img
                        alt="logo"
                        sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
                        src="/ev%20%20tools%20logo%20(1)-1100h.webp"
                        srcSet="/ev%20%20tools%20logo%20(1)-1100h.webp 1200w, /ev%20%20tools%20logo%20(1)-tablet.webp 800w, /ev%20%20tools%20logo%20(1)-mobile.webp 480w"
                        className="home-logo2"
                      />
                      <div
                        data-thq="thq-close-menu"
                        className="home-close-menu"
                      >
                        <svg viewBox="0 0 1024 1024" className="home-icon14">
                          <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                        </svg>
                      </div>
                    </div>
                    <nav className="home-links3">
                      <a
                        href="#hero"
                        className="home-link12 thq-link thq-body-small"
                      >
                        Home
                      </a>
                      <a
                        href="#title"
                        className="home-link22 thq-link thq-body-small"
                      >
                        Features
                      </a>
                      <a
                        href="#centered container"
                        className="home-link34 thq-link thq-body-small"
                      >
                        Managment
                      </a>
                      <span className="home-link4 thq-link thq-body-small">
                        Mobile
                      </span>
                      <span className="home-link5 thq-link thq-body-small">
                        云快充
                      </span>
                    </nav>
                  </div>
                </div>
              </header>
            </header>
          </div>
          <div>
            <div className="home-container13">
              <Script
                html={`<script defer>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
              ></Script>
            </div>
          </div>
        </header>
        <header className="home-content1">
          <h1 className="home-text14">
            Empower Your EV Charging Operations with Smart Solutions
          </h1>
          <p className="home-text15">
            Intuitive, robust, and reliable EV charger management at your
            fingertips
          </p>
          <div className="home-get-started2">
            <span className="home-text16">Get started</span>
            <div className="home-dashboard-preview">
              <div className="home-outline">
                <img
                  alt="pastedImage"
                  sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
                  src="/external/hero2-900w.webp"
                  loading="eager"
                  srcSet="/external//hero2-900w.webp 1200w, /external/hero2-tablet.webp 800w, /external/hero2-mobile.webp 480w"
                  className="home-image2"
                />
              </div>
            </div>
          </div>
        </header>
      </section>
      <section className="home-features">
        <div id="title" className="home-title">
          <span className="home-text17"> We are committed to excellence</span>
          <span className="home-text18">
            Our comprehensive SaaS platform simplifies the management of EV
            charging stations through a seamless blend of innovative tools and
            user-centric design. Experience real-time control, detailed
            analytics, and more – all designed to enhance operational efficiency
            and user satisfaction
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container14">
            <div className="card">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon16"
              >
                <path
                  d="M11.025 21v-2.15q-1.325-.3-2.287-1.15t-1.413-2.4l1.85-.75q.375 1.2 1.113 1.825t1.937.625q1.025 0 1.738-.462t.712-1.438q0-.875-.55-1.387t-2.55-1.163q-2.15-.675-2.95-1.612t-.8-2.288q0-1.625 1.05-2.525t2.15-1.025V3h2v2.1q1.25.2 2.063.913t1.187 1.737l-1.85.8q-.3-.8-.85-1.2t-1.5-.4q-1.1 0-1.675.488T9.825 8.65q0 .825.75 1.3t2.6 1q1.725.5 2.613 1.588t.887 2.512q0 1.775-1.05 2.7t-2.6 1.15V21z"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="home-text19">Low operating cost</span>
              <span className="home-text20">
                Our panel provides high energy efficiency, reducing charging
                costs and increasing profitability.
              </span>
            </div>
            <div className="home-publish card">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                className="home-icon18"
              >
                <path
                  d="M30 6a3.992 3.992 0 0 0-7.977-.224L9.586 8.263A3.99 3.99 0 1 0 5 13.858v8.284A3.992 3.992 0 1 0 9.858 27h8.284a3.991 3.991 0 1 0 5.595-4.586l2.487-12.437A3.995 3.995 0 0 0 30 6m-4-2a2 2 0 1 1-2 2a2 2 0 0 1 2-2M4 10a2 2 0 1 1 2 2a2 2 0 0 1-2-2m2 18a2 2 0 1 1 2-2a2 2 0 0 1-2 2m12.142-3H9.858A4 4 0 0 0 7 22.142v-8.284a3.99 3.99 0 0 0 2.977-3.634l12.437-2.487a4 4 0 0 0 1.849 1.85l-2.487 12.436A3.99 3.99 0 0 0 18.142 25M22 28a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="home-text21">Easy customization</span>
              <span className="home-text22">
                Offers various options for configuring charging stations to meet
                the specific needs of users
              </span>
            </div>
          </div>
          <div className="home-container15">
            <div className="card home-analyze">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                className="home-icon20"
              >
                <path
                  d="m21 28.6l-4.7-4.7c1.1-1.4 1.7-3.1 1.7-4.9c0-4.4-3.6-8-8-8s-8 3.6-8 8s3.6 8 8 8c1.8 0 3.5-.6 4.9-1.7l4.7 4.7zM10 25c-3.3 0-6-2.7-6-6s2.7-6 6-6s6 2.7 6 6s-2.7 6-6 6m12-13h8v2h-8zm-8-5h16v2H14zm0-5h16v2H14z"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="home-text23">Real-time monitoring</span>
              <span className="home-text24">
                Advanced functionalities allow for monitoring charging and
                analyzing data to optimize performance
              </span>
            </div>
            <div className="card home-get-leads">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                className="home-icon22"
              >
                <path
                  d="M26 20a2.97 2.97 0 0 0-1.855.66L22 19.423v-1.606a3 3 0 1 0-2 0v1.606l-2.145 1.239A2.97 2.97 0 0 0 16 20a3.02 3.02 0 1 0 2.925 2.353L21 21.155l2.075 1.198A2.998 2.998 0 1 0 26 20m-10 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1m5-10a1 1 0 1 1-1 1a1 1 0 0 1 1-1m5 10a1 1 0 1 1 1-1a1 1 0 0 1-1 1"
                  fill="currentColor"
                ></path>
                <path
                  d="M16 31A11.013 11.013 0 0 1 5 20V6.382l10.987-5.5l10.46 5.237l-.895 1.788l-9.565-4.789L7 7.618V20a9 9 0 0 0 14.4 7.201l1.2 1.599A10.9 10.9 0 0 1 16 31"
                  fill="currentColor"
                ></path>
              </svg>
              <span className="home-text25"> High security</span>
              <span className="home-text26">
                The panel is equipped with advanced security systems to protect
                users and devices during charging.
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-how-it-works">
        <div className="home-centered-container1">
          <div className="home-category1">
            <div className="home-headng">
              <span className="home-text27">Mision &amp; Vision</span>
              <span className="home-text28">
                <span>
                  The company&apos;s mission is to improve the management of EV
                  charging infrastructure by offering powerful, scalable, and
                  easy-to-use solutions that meet the evolving needs of the
                  electric vehicle industry. The vision is to become the leading
                  global provider of EV charging management software, enabling
                  sustainable and efficient transportation worldwide.
                </span>
                <br></br>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-banners">
        <div
          id="banner - advanced analytics"
          className="home-banner-advanced-analytics1"
        >
          <div id="centered container" className="home-centered-container2">
            <div className="home-image-container1">
              <img
                alt="pastedImage"
                sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
                src="/file%20(20)2-1200w.webp"
                loading="lazy"
                srcSet="/file%20(20)2-1200w.webp 1200w, /file%20(20)2-tablet.webp 800w, /file%20(20)2-mobile.webp 480w"
                className="home-cards-image1"
              />
            </div>
            <div className="home-right1">
              <h2 className="home-text32 title">
                <span>
                  Intuitive User
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text34">Dashboard</span>
              </h2>
              <div className="home-category2">
                <span className="home-text35">
                  Access a dynamic, easy-to-navigate dashboard that provides a
                  bird&apos;s-eye view of your entire charging network. Track
                  real-time operations with interactive charts, visualize
                  charger locations on a detailed map, and monitor key metrics
                  that matter.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-banner1">
          <div className="home-container16">
            <div className="home-left1">
              <h2 className="home-text36 title">
                <span>
                  Real-Time Charger
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text38">Monitoring &amp; Control</span>
                <span>.</span>
              </h2>
              <span className="home-text40">
                Gain real-time insights into each charger&apos;s status and
                control operations remotely. Whether it&apos;s starting or
                stopping a charge or adjusting settings, handle it all from
                anywhere, ensuring maximum uptime and service reliability
              </span>
            </div>
            <div className="home-image-container2">
              <img
                alt="pastedImage"
                sizes="(min-width: 768px) 800px, 480px"
                src="/charger%20monitor%20and%20control%20(1)1-700h.webp"
                loading="lazy"
                srcSet="/charger%20monitor%20and%20control%20(1)1-700h.webp 800w, /charger%20monitor%20and%20control%20(1)1-mobile.webp 480w"
                className="home-cards-image2"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="home-banner-advanced-analytics2">
        <div className="home-centered-container3">
          <div className="home-image-container3">
            <img
              alt="pastedImage"
              sizes="(min-width: 768px) 800px, 480px"
              src="/transaction%20details-700h.webp"
              loading="lazy"
              srcSet="/transaction%20details-700h.webp 800w, /transaction%20details-mobile.webp 480w"
              className="home-cards-image3"
            />
          </div>
          <div className="home-right2">
            <h2 className="home-text41 title">
              <span>
                Comprehensive
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text43">Transaction Management</span>
            </h2>
            <div className="home-category3">
              <span className="home-text44">
                Filter, analyze, and export transaction data effortlessly. Our
                platform enables detailed analysis of every charging session,
                helping you make informed decisions to optimize your services.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner2">
        <div className="home-container17">
          <div className="home-left2">
            <h2 className="home-text45 title">
              <span>
                Advanced User
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text47">Management</span>
            </h2>
            <span className="home-text48">
              Manage user profiles comprehensively with options to allow
              multiple RFID cards per user, link cards to a single account, and
              control mobile app access. Implement credit management strategies,
              including negative balance permissions, to accommodate all user
              needs
            </span>
          </div>
          <div className="home-image-container4">
            <img
              alt="pastedImage"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/prepaid%20credit%20management%20(1)-1200w.webp"
              loading="lazy"
              srcSet="/prepaid%20credit%20management%20(1)-1200w.webp 1200w, /prepaid%20credit%20management%20(1)-tablet.webp 800w, /prepaid%20credit%20management%20(1)-mobile.webp 480w"
              className="home-cards-image4"
            />
          </div>
        </div>
      </div>
      <div className="home-banner-advanced-analytics3">
        <div className="home-centered-container4">
          <div className="home-image-container5">
            <img
              alt="pastedImage"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/chargers%20drivers%20grouping-1200w.webp"
              loading="lazy"
              srcSet="/chargers%20drivers%20grouping-1200w.webp 1200w, /chargers%20drivers%20grouping-tablet.webp 800w, /chargers%20drivers%20grouping-mobile.webp 480w"
              className="home-cards-image5"
            />
          </div>
          <div className="home-right3">
            <h2 className="home-text49 title">
              <span>
                Efficient Group
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text51">Management</span>
            </h2>
            <div className="home-category4">
              <span className="home-text52">
                Organize and manage groups or companies with ease. Isolate and
                group chargers and drivers, and set permissions for inter-group
                access to streamline operations and improve administrative
                efficiency.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner3">
        <div id="#container" className="home-container18">
          <div className="home-left3">
            <h2 className="home-text53 title">般信息</h2>
            <span className="home-text54">
              <span>
                充电桩使用云快充协议（充电桩与云快充服务平台交互协议）1.6版本与第三方系统进行通信。该协议由江苏云快充新能源科技有限公司（新能源科技有限公司）开发，基于中国国家电网104充电桩规范。目前，该协议仅由中国境内的几家充电桩制造商使
              </span>
              <br></br>
              <span>用。</span>
              <br></br>
              <span>General Information</span>
              <br></br>
              <br></br>
              <span>
                Charging devices use the Cloud Fast Charge Protocol (charging
                pile and cloud fast charging service platform interaction
                protocol) version 1.6 for communication with third-party
                systems.This protocol was developed by Jiangsu Cloud Fast Charge
                New Energy Technology Co., Ltd. (New Energy Technology Co.,
                Ltd.), based on the China State Grid 104 charging pile
                specification. Currently, the protocol is only used by charging
                manufacturers within China.EV Tools fully support the 云快充
                (Cloud Fast Charging) protocol
              </span>
            </span>
          </div>
          <div className="home-image-container6">
            <img
              alt="pastedImage"
              sizes="(min-width: 768px) 800px, 480px"
              src="/file%20(34)1-600h.webp"
              loading="lazy"
              srcSet="/file%20(34)1-600h.webp 800w, /file%20(34)1-mobile.webp 480w"
              className="home-cards-image6"
            />
          </div>
        </div>
      </div>
      <section className="home-action-bar">
        <div id="#action" className="home-action">
          <div className="home-heading1">
            <span className="home-text63 title">Mobile Application</span>
            <span className="home-text64">
              Our mobile app puts control in the hands of drivers. With features
              like remote start/stop, credit monitoring, route management, and
              QR code functionality, drivers enjoy a seamless and connected
              charging experience. Compatible with iOS and Android devices.
            </span>
            <div className="home-pills">
              <div className="home-container19">
                <a
                  href="https://apps.apple.com/us/app/icharge-app/id6479523452"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link13"
                >
                  <YouTube1
                    rootClassName="you-tube1root-class-name1"
                    pastedImageSrc="/logos/available-on-the-app-store.svg"
                    className="home-component1"
                  ></YouTube1>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.icharge.mobile.app"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link14"
                >
                  <YouTube1
                    rootClassName="you-tube1root-class-name"
                    pastedImageSrc="/logos/google-play-store-logo-png-transparent%40%20(1).svg"
                    className="home-component2"
                  ></YouTube1>
                </a>
              </div>
            </div>
          </div>
          <div className="home-images">
            <img
              alt="image"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/white%20label%20mobile%20application%20(1)-1200w.webp"
              loading="lazy"
              srcSet="/white%20label%20mobile%20application%20(1)-1200w.webp 1200w, /white%20label%20mobile%20application%20(1)-tablet.webp 800w, /white%20label%20mobile%20application%20(1)-mobile.webp 480w"
              className="home-image3"
            />
          </div>
        </div>
      </section>
      <section className="home-faq">
        <div className="home-heading2">
          <span className="home-text65">Frequently Asked Questions</span>
        </div>
        <div className="home-accordion">
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header2">
              <span className="home-text66">
                How can I monitor the status of a charger in real-time?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text67">
                You can monitor the status of any charger in real-time through
                our management platform. It provides you with a detailed
                overview of each charger&apos;s condition, including whether it
                is active, busy, or under maintenance. This helps ensure
                continuous performance and address potential issues.
              </span>
            </div>
          </div>
          <div className="accordionContainer">
            <div className="home-header3">
              <span className="home-text68">
                Is the application compatible with both platforms, iOS and
                Android?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text69">
                Yes, our mobile application is compatible with both iOS and
                Android devices. This allows drivers easy access to charging
                functionalities from any device they use, providing a seamless
                and convenient experience.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header4">
              <span className="home-text70">
                How can I filter and analyze charging transaction data?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text71">
                Our platform allows you to filter transaction data by various
                parameters, such as date, charger location, user, and many more.
                You can also analyze this data to gain a better understanding of
                usage and optimize your services. The data can be exported for
                further analysis or reporting.
              </span>
            </div>
          </div>
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header5">
              <span className="home-text72">
                How can I organize and manage groups of chargers and drivers?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text73">
                Our platform allows you to create groups of chargers and drivers
                based on your administrative needs. You can isolate or group
                chargers and drivers for specific operations, setting
                permissions for restricted access between groups to simplify
                management and enhance operational efficiency.
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="home-container21">
            <Script
              html={`<script>
// Accordion - Code Embed

const accordionContainers = document.querySelectorAll(".accordionContainer"); // All accordion containers
const accordionContents = document.querySelectorAll(".accordionContent"); // All accordion content
const accordionIcons = document.querySelectorAll(".accordionIcon"); // All accordion icons

accordionContents.forEach((accordionContent) => {
    accordionContent.style.display = "none"; //Hides all accordion contents
});

accordionContainers.forEach((accordionContainer, index) => {
    accordionContainer.addEventListener("click", () => {
        accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
        });

        accordionIcons.forEach((accordionIcon) => {
            accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
        });

        if (accordionContainer.classList.contains("accordion-open")) { // Checks if this container has class "accordion-open"
            accordionContainer.classList.remove("accordion-open"); // Removes class "accordion-open"
        } else {
            accordionContainers.forEach((accordionContainer) => {
                accordionContainer.classList.remove("accordion-open"); // Removes class "accordion-open" from all containers
            });

            accordionContainer.classList.add("accordion-open"); // Adds class "accordion-open" to this container
            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        }
    });
});

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
            ></Script>
          </div>
        </div>
      </section>
      <div className="home-contact1">
        <form
          action="https://formsubmit.co/info@evtools.io"
          method="POST"
          enctype="application/x-www-form-urlencoded"
          className="home-form"
        >
          <h1 className="home-text74">Want to work with us?</h1>
          <span className="home-text75">EMAIL</span>
          <input
            type="text"
            name="email"
            target="email"
            required="true"
            placeholder="email"
            className="home-textinput"
          />
          <span className="home-text76">MESSAGE</span>
          <textarea
            cols="80"
            name="message"
            rows="4"
            placeholder="Type a message"
            className="home-textarea"
          ></textarea>
          <button type="submit" className="home-button button">
            Send message
          </button>
        </form>
      </div>
      <footer id="footer" className="home-footer">
        <div className="home-bottom">
          <img
            alt="pastedImage"
            sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
            src="/ev%20%20tools%20logo%20(1)-1100h.webp"
            loading="lazy"
            srcSet="/ev%20%20tools%20logo%20(1)-1100h.webp 1200w, /ev%20%20tools%20logo%20(1)-tablet.webp 800w, /ev%20%20tools%20logo%20(1)-mobile.webp 480w"
            className="home-branding"
          />
        </div>
        <div className="home-top2">
          <div className="home-right4">
            <div className="home-category5">
              <span className="home-text77">
                <span>Address:</span>
                <br></br>
                <br></br>
              </span>
              <div className="home-links4">
                <span className="home-text81">
                  <span className="home-text82"> Kavaja Street,</span>
                  <br className="home-text83"></br>
                  <br></br>
                  <span className="home-text85">
                    1 km, Building alongside the vetting process.
                  </span>
                </span>
              </div>
            </div>
            <div className="home-category6">
              <span className="home-text86">
                <span className="home-text87">About Us</span>
                <br className="home-text88"></br>
                <br></br>
              </span>
              <div className="home-links5">
                <a href="#hero" className="home-link15">
                  Home
                </a>
                <a href="#title" className="home-link16">
                  Features
                </a>
                <a href="#centered container" className="home-link17">
                  Managment
                </a>
                <span className="home-text90">Terms of service</span>
                <span className="home-text91">Privacy policy</span>
              </div>
            </div>
            <div className="home-contact2">
              <span className="home-text92">
                <span className="home-text93">Contact</span>
                <br></br>
              </span>
              <a
                href="mailto:use@active-app.com?subject=Support"
                className="home-link18"
              >
                <span className="home-text95">
                  Email: 
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>info@evtools.io</span>
                <br className="home-text97"></br>
                <br></br>
              </a>
              <a href="tel:+355694080227" className="home-link19">
                Tel:  +355694080227
              </a>
            </div>
            <div className="home-links6">
              <span className="home-text99">
                <span>Social media</span>
                <br></br>
              </span>
              <a
                href="https://x.com/i/flow/login"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link20"
              >
                Twitter
              </a>
              <a
                href="https://api.linkedin.com/login"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link23"
              >
                Linkedin
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link24"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link25"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>
        <span className="home-text102">Copyright © Active - 2024</span>
      </footer>
    </div>
  )
}

export default Home
